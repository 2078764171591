/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useEffect } from "react";
import { config } from "../config";

import "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { useUser } from "../providers/useUser";

export const CookieConsent = () => {
  const { currentSchool } = useUser();

  const privacyUrl = currentSchool?.privacyUrl;

  const privacyUrlLinkEs = privacyUrl
    ? `<a target="_blank" href="${privacyUrl}" class="cc-link">aviso de privacidad</a>`
    : "aviso de privacidad";
  const privacyUrlLinkEn = privacyUrl
    ? `<a target="_blank" href="${privacyUrl}" class="cc-link">privacy policies</a>`
    : "privacy policies";

  useEffect(() => {
    if (config.VITE_APP_ENV === "production") {
      if (!document.getElementById("cc--main")) {
        window.CC = initCookieConsent();
        const storedLanguage = localStorage.getItem("ada-LNG") ?? undefined;
        const language = storedLanguage?.split("-")[0] ?? "en";

        window.CC.run({
          revision: 3,
          current_lang: language,
          autoclear_cookies: true,
          page_scripts: true,
          cookie_name: "ada_cookie_consent",
          languages: {
            es: {
              consent_modal: {
                title: "🍪 Cookie time!",
                description:
                  'En este sitio web utilizamos cookies para recopilar datos estadísticos que nos permitan personalizar y mejorar el contenido y anuncios de acuerdo al análisis de nuestro tráfico. También analizamos el flujo de información entre nuestras redes sociales y el sitio web, compartimos información con aliados de medios sociales y/o análisis con el objetivo de mejorar la experiencia y oferta a nuestros usuarios. <button type="button" data-cc="c-settings" class="cc-link">Ajustes</button>',
                primary_btn: { text: "Acepto", role: "accept_all" },
                secondary_btn: { text: "No Acepto", role: "accept_necessary" },
              },
              settings_modal: {
                title: "Preferencias de cookies",
                save_settings_btn: "Guardar preferencias",
                accept_all_btn: "Aceptar",
                reject_all_btn: "Rechazar",
                close_btn_label: "Cerrar",
                cookie_table_headers: [
                  { col1: "Nombre" },
                  { col2: "Dominio" },
                  { col3: "Expiración" },
                  { col4: "Descriptión" },
                ],
                blocks: [
                  {
                    title: "📣 Uso de cookies",
                    description: `En este sitio web utilizamos cookies para recopilar datos estadísticos que nos permitan personalizar y mejorar el contenido y anuncios de acuerdo al análisis de nuestro tráfico. También analizamos el flujo de información entre nuestras redes sociales y el sitio web, compartimos información con aliados de medios sociales y/o análisis con el objetivo de mejorar la experiencia y oferta a nuestros usuarios. Para más detalles revisa nuestro ${privacyUrlLinkEs}.`,
                  },
                  {
                    title: "Cookies estrictamente necesarias",
                    description:
                      "No se pueden desactivar dentro de esta web. Nos permiten controlar el tráfico y comunicación de datos internos, así como elementos de seguridad y de interacción del sitio con el navegador de los usuarios. Si continúas en el sitio web aceptas su uso",
                    toggle: { value: "necessary", enabled: !0, readonly: !0 },
                  },
                  {
                    title: "Cookies de rendimiento y análisis",
                    description:
                      "Sirven para  recopilar la información relacionada con lo que haces en la página web para analizar su usabilidad y mejorar la experiencia de navegación de los usuarios",
                    toggle: { value: "analytics", enabled: !1, readonly: !1 },
                    cookie_table: [
                      {
                        col1: "^_ga",
                        col2: "google.com",
                        col3: "2 años",
                        col4: "Seguimiento para Google Analytics",
                        is_regex: !0,
                      },
                      {
                        col1: "_fbp",
                        col2: "facebook.com",
                        col3: "3 meses",
                        col4: "Seguimiento de campañas de Facebook",
                      },
                      {
                        col1: "_gcl_au",
                        col2: "google.com",
                        col3: "permanente",
                        col4: "Seguimiento de campañas de Google",
                      },
                    ],
                  },
                ],
              },
            },
            en: {
              consent_modal: {
                title: "🍪 Cookie time!",
                description:
                  'On this website we use cookies to collect statistical data that allow us to personalize and improve the content and advertisements according to the analysis of our traffic. We also analyze the flow of information between our social networks and the website, we share information with social media partners and/or analyzes with the aim of improving the experience and offer to our users. <button type="button" data-cc="c-settings" class="cc-link">Settings</button>',
                primary_btn: { text: "Accept", role: "accept_all" },
                secondary_btn: { text: "Reject", role: "accept_necessary" },
              },
              settings_modal: {
                title: "Cookie preferences",
                save_settings_btn: "Save preferences",
                accept_all_btn: "Accept",
                reject_all_btn: "Reject",
                close_btn_label: "Close",
                cookie_table_headers: [
                  { col1: "Name" },
                  { col2: "Domain" },
                  { col3: "Expiration" },
                  { col4: "Description" },
                ],
                blocks: [
                  {
                    title: "📣 Cookies usage",
                    description: `On this website we use cookies to collect statistical data that allow us to personalize and improve the content and advertisements according to the analysis of our traffic. We also analyze the flow of information between our social networks and the website, we share information with social media partners and/or analyzes with the aim of improving the experience and offer to our users. For more details, check our ${privacyUrlLinkEn} .`,
                  },
                  {
                    title: "Strictly necessary cookies",
                    description:
                      "They cannot be deactivated within this website. They allow us to control the traffic and communication of internal data, as well as security elements and interaction of the site with the users' browser. If you continue on the website you accept its use",
                    toggle: { value: "necessary", enabled: !0, readonly: !0 },
                  },
                  {
                    title: "Performance and analytics cookies",
                    description:
                      "They serve to collect information related to what you do on the website to analyze its usability and improve the user's browsing experience.",
                    toggle: { value: "analytics", enabled: !1, readonly: !1 },
                    cookie_table: [
                      {
                        col1: "^_ga",
                        col2: "google.com",
                        col3: "2 years",
                        col4: "Tracking for Google Analytics",
                        is_regex: !0,
                      },
                      {
                        col1: "_fbp",
                        col2: "facebook.com",
                        col3: "3 months",
                        col4: "Facebook Campaign Tracking",
                      },
                      {
                        col1: "_gcl_au",
                        col2: "google.com",
                        col3: "permanent",
                        col4: "Google Campaign Tracking",
                      },
                    ],
                  },
                ],
              },
            },
          },
        });
      }
    }
  }, []);

  return null;
};
